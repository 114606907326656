const setSwiper = function(selector) {
  const mySwiper = new Swiper (selector, {
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    loop: true,
    autoplay: 4000,
    pagination: '.swiper-pagination',
    paginationClickable: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    onInit: function(){
      $('.swiper-slide-active a,.swiper-slide-next a,.swiper-slide-prev a').css('opacity', '1');
    },
    onSlideChangeStart: function(){
      $('.swiper-slide-active a').addClass("slide__center");
      $('.swiper-slide a').css('opacity', '0');
      $('.swiper-slide-active a,.swiper-slide-next a,.swiper-slide-prev a').css('opacity', '1');
    },
  });
  $(selector).mouseenter(function(){
    mySwiper.stopAutoplay();
  });
  $(selector).mouseleave(function(){
    mySwiper.startAutoplay();
  });

  return mySwiper
}